<template>
  <div >
    <div >
      <div >
        <div>
          <h2 class="titlePage_subtitle titlePage">Lift Tabs</h2>
          <div class="row">
            <div class="col-md-6" :class="{'col-md-12' : package}">
              <form action="" class="form" v-on:submit.prevent>
                <div class="bordered">
                  <h3 class="text">Mount</h3>
                  <div class="form__wrapRadio">
                    <label class="form__label form__label_row">
                      <input type="radio" value="inside"
                             v-model="selected.settings.position"
                             hidden name="mount"
                             class="form__radio"
                              @change="setValues">
                      <span class="form__radio_icon"></span>
                      <span class="form__labelTitle">Inside</span>
                    </label>

                    <label class="form__label form__label_row">
                      <input type="radio" value="outside"
                             v-model="selected.settings.position"
                             hidden name="mount"
                             class="form__radio"
                             @change="setValues">
                      <span class="form__radio_icon"></span>
                      <span class="form__labelTitle">Outside</span>
                    </label>
                  </div>
                </div>

                <div class="bordered">
                  <h3 class="text">Position</h3>
                  <div class="justify-content-center">
                    <label class="form__label form__label_row">
                      <input v-model="selected.settings.top"
                             type="checkbox"
                             hidden class="form__checkbox"
                             @change="setValues"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Top</span>
                    </label>
                  </div>

                  <div class="justify-content-between">

                    <label class="form__label form__label_row">
                      <input v-model="selected.settings.left"
                             type="checkbox"
                             hidden
                             class="form__checkbox"
                             @change="setValues"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Left</span>
                    </label>

                    <label class="form__label form__label_row">
                      <input v-model="selected.settings.right"
                             type="checkbox"
                             hidden
                             class="form__checkbox"
                             @change="setValues"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Right</span>
                    </label>
                  </div>

                  <div class="justify-content-center">
                    <label class="form__label form__label_row">
                      <input v-model="selected.settings.bottom"
                             type="checkbox"
                             hidden
                             class="form__checkbox"
                             @change="setValues"
                      >
                      <span class="form__checkbox_icon"></span>
                      <span class="form__labelTitle">Bottom</span>
                    </label>
                  </div>
                </div>
                <p class="text_center" :class="{'text_red': view_inside}">Please note: Looking from {{view_inside ? 'inside' : 'outside'}}</p>
                <template v-if="actionLinksPortalTarget">
                  <portal :to="actionLinksPortalTarget">
                    <button type="button" class="btn btn_transparent" @click.prevent="saveOpt"><svg-icon name="solid/check" /> Done</button>
                  </portal>
                </template>
                <template v-else>
                  <button type="button" class="btn btn_default" @click.prevent="saveOpt"><svg-icon name="solid/check" /> Done</button>
                </template>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  import breadcrumbs from "./../../parts/breadcrumbs";

  export default {
    name: "LiftTabs",
    data() {
      return {
        selected: {
            components: {},
            settings: {},
        },
        view_inside : ''
      }
    },

    components: {breadcrumbs},

    props: ['options', 'package', 'set', 'checkIsSetPackage', 'actionLinksPortalTarget'],

    computed: {
        ...mapGetters({
            order: 'orderPosition/orderPosition',
            selectColours: 'optionColours/openModal',
        }),

        settings() {
            return this.$store.getters['orderPosition/getCurrentOptionSettings'];
        },

    },
    methods: {
      saveOpt() {
          this.$store.commit('orderPosition/optionNext', 'LFTT');

      },

      setValues(){
          this.$store.commit('orderPosition/setOption', {option: 'LFTT', selection: this.selected});
      },

    },


    mounted() {
        this.selected = this.settings;
    },
  }
</script>

<style scoped lang="scss">
  @import '../../../../src/assets/scss/utils/vars';

  .modal {
    z-index: 99;
  }

  .form {
    &__input {
      border-color: $navy;
    }

    .bordered {
      border: 1px solid $navy;
      padding: 10px;
      margin-bottom: 20px;
    }

    &__labelTitle {
      color: $navy
    }

    .justify-content-center, .justify-content-between {
      display: flex;
      margin: 10px;
    }

    .btn {
      margin: 0 0 20px;
    }
  }

  .modal__close {
    color: $green;
  }

  .text_white {
    position: absolute;
    bottom: 3rem;
    left: auto;
    right: 2rem;
    display: inline-block;
    width: auto;
  }


</style>
